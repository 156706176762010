<template>
  <div>
    <template v-if="currentStep === 1">
      <div class="prompt-combo-list">
        <a-row type="flex" justify="space-between">
          <a-input-search
            class="search-input"
            allowClear
            placeholder="请输入prompt组合名称查询"
            v-model="form.promptCombinationName"
            @search="getTableData(true)"
          ></a-input-search>

          <a-button type="primary" @click="addPromptCombo">新增prompt组合</a-button>
        </a-row>
        <div class="data-table">
          <a-table :loading="loading" :columns="columns" :data-source="tableData" :pagination="false" row-key="id">
            <div slot="operation" slot-scope="text, record">
              <a-space>
                <a-button type="link" @click="handleToCombo(record.id)">查看</a-button>
              </a-space>
            </div>
          </a-table>
          <base-pagination
            :current-page="pagination.current"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
          />
        </div>
      </div>
    </template>
    <template v-if="currentStep === 2">
      <PromptCombo ref="PromptCombo" @back="handleBack" />
    </template>
  </div>
</template>

<script>
import api from '@/api/AIGC';
import PromptCombo from './promptCombo.vue';

export default {
  data() {
    const columns = [
      {
        title: 'prompt组合名称',
        dataIndex: 'promptCombinationName',
      },
      {
        title: '子任务数',
        dataIndex: 'promptCount',
      },
      {
        title: '创建人',
        dataIndex: 'creator',
      },
      {
        title: '创建时间',
        dataIndex: 'ctime',
      },
      {
        align: 'center',
        title: '操作',
        scopedSlots: { customRender: 'operation' },
      },
    ];
    return {
      columns,
      loading: false,
      form: {
        promptCombinationName: undefined,
      },
      tableData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      currentStep: 1,
    };
  },
  components: { PromptCombo },
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData(isFirst) {
      this.loading = true;
      const params = {
        ...this.form,
        page: isFirst ? 1 : this.pagination.current,
        size: this.pagination.pageSize,
      };
      api
        .getPromptComboList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.tableData = data?.list;
            this.pagination.current = data?.page;
            this.pagination.total = data?.total;
          } else {
            this.$message.error(`${message}`);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getTableData();
    },
    handleToCombo(id) {
      this.currentStep = 2;
      this.$nextTick(() => {
        this.$refs.PromptCombo.handleInit(id);
      });
      // this.$router.push({
      //   name: 'promptCombo',
      //   query: {
      //     id,
      //   },
      // });
    },
    addPromptCombo() {
      this.currentStep = 2;
      this.$nextTick(() => {
        this.$refs.PromptCombo.getAllPromptList();
      });
      // this.$router.push({
      //   name: 'promptCombo',
      // });
    },
    handleBack(){
      this.currentStep = 1;
      this.getTableData();
    }
  },
};
</script>

<style scoped lang="less">
.search-input {
  width: 200px;
  margin-bottom: 15px;
}
</style>
